// components/FileUpload.js
"use client"
import { useState, useEffect } from 'react';
import axios from 'axios';
import { PlusCircledIcon } from "@radix-ui/react-icons";
import { useRouter } from 'next/navigation';
import AssistantCard from "@/components/ui/assitantCard";
import DynamicImage from './ui/DynamicImage';



export default function SuperAdminPage({userDetails}) {

  const [quizDetails, setQuizDetails] = useState(null);
  const router = useRouter();

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.get(`api/quiz`);
            const Quizzesdetails = response.data.quizDetails;
            setQuizDetails(Quizzesdetails);
        } catch (error) {
            console.error("Error fetching quizzes:", error);
        }
    };
    fetchData();
  }, []);

  const createAppPage = () => {
    router.push('/file-upload');
  }


  return (
    <div className='flex flex-col'>  
        <button onClick={() => createAppPage()} className="mr-4 h-12 w-1/6 self-end my-4 bg-gradient-to-r from-[#85CC1F] to-[#067A3B] text-white rounded-lg flex items-center gap-3 py-2 px-4">
            <PlusCircledIcon height={20} width={20} />
            Create New App
        </button>
        <div>
            {quizDetails && quizDetails.length === 0 ? (
                <div className="flex flex-col items-center bg-[#F7FAFF] justify-center h-64 border-2 border-graylight rounded-lg text-center">
                  <DynamicImage src="/danger.svg" width={30} height={30} alt="icon" className="mb-4" />
                  No Apps created as of now.
                </div>
            ) : (
                <div className="w-full flex flex-col gap-5 text-gray-800">
                <div className="flex flex-wrap gap-4 justify-center items-center sm:justify-start sm:items-start">
                    {quizDetails && 
                    quizDetails.map((quiz) => (
                        <div  key={quiz._id} className='w-full'>
                            <h3 className="my-4 text-[#496188] text-2xl font-semibold font-['DM Sans']">Apps <strong>{quiz.username}</strong> have created</h3>

                            <div className="w-full md:w-full flex">
                                <AssistantCard
                                id = {userDetails._id}
                                displayName={quiz.displayName}
                                userProfilesHandler= {() => null}
                                dashboardHandler={() => null}
                                userDetails={userDetails}
                            />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            )}
        </div>
    </div>
   
  );
}
